* {
  margin: 0;
  padding: 0;
  color: #3d3d3d;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.disable-scrollbar::-webkit-scrollbar {
  display: none;
}

.disable-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
